<template>
  <div class="row">
    <modal :adaptive="true" height="auto" name="open_to_settings_table" width="300">
      <div class="row mx-0 py-7">
        <div class="col-12 row mx-0 py-2 navy-blue-border-bottom-1px text-xl font-weight-bold">
          <div class="col-6 text-center">{{ $t('general.colum_name') }}</div>
          <div class="col-6 text-center">{{ $t('general.add') }}</div>
        </div>
        <div class="col-12  mx-0 py-2 text-xl">
          <draggable v-model="fieldsToViewEdit" v-bind="dragOptions" :move="onMove" class="list-group py-4" tag="ul"
            @end="isDragging=false" @start="isDragging=true">
            <transition-group :name="'flip-list'" type="transition">
              <div v-for="element in fieldsToViewEdit" :key="element.sort"
                class="col-12 list-group-item w-300px d-flex justify-content-between ">
                <span class="col-6"> {{ $t(element.name) }}</span>

                <span class="col-6 d-flex justify-content-center">
                  <input v-model="element.type_status" style="font-size: 10px " type="checkbox"></span>
              </div>
            </transition-group>
          </draggable>
          <button class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeModal">
            {{ $t('esc.new_send_close').toUpperCase() }}
          </button>
          <button class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer"
            @click="onSubmitToSaveSettingTable">{{
            $t('esc.new_send')
            }}
          </button>
        </div>

      </div>
    </modal>
    <div class="col-md-12">
      <div id="filter-card" class="row py-3 px-4">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult()">
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters">
              <div class="col-lg-6 col-md-3 col-sm-12 my-2 row px-0 mx-0">
                <input-filter :helper-text="$t('general.search')" :model.sync="filters.search"
                  :placeholder="$t('general.search_filter')" title="general.search"></input-filter>
              </div>
              <div class="col-lg-4 col-md-5 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter :helper-text="$t('general.start_date')" :model.sync="filters.start_date"
                    name="start_time" title="general.start_date">
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter :helper-text="$t('general.end_date')" :model.sync="filters.end_date" name="end_date"
                    title="general.end_date">
                  </date-filter>
                </div>
              </div>
              <div
                class="col-lg-1 col-md-2 my-lg-2 my-2 my-sm-0 row mx-0 px-1 d-flex flex-column justify-content-center navy-blue-border-1px border-10px">
                <CustomMultiSelect :item-per-row="1" :max="1" :model.sync="filters.user_id"
                  :name="$t('esc.new_please_select')" :not-list="true" :options="userOptions" :required="true"
                  :title="null" height="30vh" style="margin-bottom: 0 !important;" width="200px">
                  <template slot="inputDesign">
                    <div class="w-100 d-flex justify-content-center">
                      <span class="w-10 items-center mr-2" v-html="getIconByKey('icons.esc.userIcon', {
                        class: 'w-35px h-35px d-inline-block object-fill cursor-pointer'
                      })">
                      </span>
                    </div>
                  </template>
                </CustomMultiSelect>
                <span class="text-lg font-weight-bold d-block text-center">{{
                filters.user_id == null ? $t('esc.please_select_user') : userOptions[filters.user_id]
                }}</span>
              </div>
              <div
                class="col-xl-1 col-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important;">
                <div class="col-12 pr-0">
                  <button class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click.prevent="filterResult()">{{
                    $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters">
                    {{ $t('general.clear') }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <context-menu :display="showContextMenu" ref="menu">
          <ul>
            <li @click="openNewTab"> {{ $t('order.open_new_tab') }} </li>
          </ul>
    </context-menu>
    <div class="col-md-12">
      <dashboard-box :title="$t('esc.esc_list')">
        <template v-slot:toolbar>

        </template>

        <template v-slot:preview>
          <div class="fixed" style="position: fixed !important; top: 220px; right: 15px;">
            <div class="w-60px h-60px" @click="openPopToSettingTable">
              <span v-html="getIconByKey('icons.waybill.edit', {
                class: 'w-40px h-40px d-inline-block object-fill cursor-pointer'
              })">
              </span>
            </div>
          </div>
          <div @contextmenu.prevent="onContextMenu">
            <data-table :bordered="false" :borderless="true" :fields.sync="tableFields" :hover="false"
            :items="formattedESCs" :no-border-collapse="true" :outlined="false" :page.sync="page"
            :perPage.sync="perPage" :striped="false" :infiniteId="infiniteId" tableVariant="''" @hitBottom="onHitBottom"
            @rowClicked="onRowClicked" @rightClick="onRightClick"
            :sortBy.sync="order" 
            :sortDesc.sync="sort"
            ></data-table>
          </div>
          <div v-if="isUserGranted('EscTable', ['create'], false)" class="fixed" style="bottom: 20px; right: 40px;">
            <div class="w-60px h-60px" @click="openCreateNewESCPage">
              <span v-html="getIconByKey('icons.waybill.waybill_entry', {
                class: 'w-60px h-60px d-inline-block object-fill cursor-pointer'
              })">
              </span>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from 'vuex'
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import TopWaybillNav from "@/view/pages/waybill/layouts/index/TopWaybillNav";
import SecondWaybillNav from "@/view/pages/waybill/layouts/index/SecondWaybillNav";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";

import {
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
  ITEMS,
  UPDATE_ITEM_BY_ID,
  SET_FILTER,
  FILTER
} from "@/core/services/store/esc/esc.module";

import {
  GET_ITEMS,
  GET_ITEMS as REST_GET_ITEM,
} from "@/core/services/store/REST.module";
import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import draggable from 'vuedraggable'
import ContextMenu from "@/assets/components/contextMenu/ContextMenu";
import {ESC_INDEX_START_DATE_STORAGE_NAME, ESC_INDEX_END_DATE_STORAGE_NAME} from "@/core/storage/storage-names"

export default {
  name: "EscIndex",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    TopWaybillNav,
    SecondWaybillNav,
    DateFilter,
    CustomMultiSelect,
    draggable,
    ContextMenu
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER,
      items: ITEMS,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    tableFields() {
      let fields = [];
      let self = this;
      for (const [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
        if (value.status === true) {
          fields.push({
            'key': key,
            'value': this.filter && this.filter.hasOwnProperty(key) ? this.filter[key] : '',
            'label': this.$t(value.name),
            'sortable': value.status,
            'sort': value.sort,
            'class': "text-center border border-top-0 border-left-0",
            'tdClass': "cursor-pointer",
          })
        }
      }
      return _.sortBy(fields, ['sort']);
    },
    formattedESCs() {
      if (!this.items || this.items.data === null || this.items.data === undefined) return [];
      let temp = [];
      this.items.data.forEach(item => {
        temp.push({
          'id': item.id,
          'table_image': item.image_link,
          'date': moment(item.date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
          'updated_date': moment(item.updated_at).format(MOMENT_SYSTEM_DATE_FORMAT),
          'prepared': item.user.name,
          'user': item.company.name,
          'model': item.model,
          'esc_number': item.esc_number,
          'copied_id': item.copied_id,
          'description': item.description,
          'in_use': item.in_use,
          'order_dependent': item.order ? item.order.order_number : '-',
          'repeated_esc_sequence':item.repeated_esc_sequence
        })
      })
      return temp;
    },
  },
  data() {
    return {
      // Filters & pagination
      infiniteId: 1,
      showContextMenu: false,
      newTabRoute:null,
      editable: true,
      isDragging: false,
      delayedDragging: false,
      sort: "desc",
      order: "id",
      sampleStatuses: [],
      fieldsToViewEdit: [],
      search: null,
      paginate: [],
      page: 1,
      perPage: 25,
      currentStoreType: 99999999,
      selectedUsers: null,
      userOptions: [],
      startDate: moment().subtract(30, "d"),
      endDate: moment().add(1, 'd'),
      download: false,
      modelTableItem: [
        {
          user_id: null,
          model_name: null,
          table_fields: {},
        }
      ],
      storeUrl: 'api/esc-tables',
      escListUrl: 'api/esc-tables'

    };
  },
  methods: {
    ...mapMutations({
      'setFilter': SET_FILTER,
    }),
    openCreateNewESCPage() {
      this.$router.push({ name: 'esc.entry.new', params: { type: 1 }, query: this.$route.query })
    },
    getTableItem() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/model_table',
        filters: {
          model_name: 'esc_table'
        },
        acceptPromise: true,
        showLoading: false,
      }).then(result => {
        if (result.status) {
          for (let i = 0; i < result.data.length; i++) {
            self.modelTableItem = result.data;
            self.modelTableItem[i].table_fields = JSON.parse(result.data[i].table_fields)
          }
          for (const [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
            self.fieldsToViewEdit.push({
              'name': this.$t(value.name),
              'sort': value.sort,
              'type': value.type,
              'type_status': value.status,
              'key': key,
            })
            self.fieldsToViewEdit = _.sortBy(self.fieldsToViewEdit, ['sort']);
          }
        }
      });
    },
    openNewTab(){
      if (this.isUserGranted('Sample', ['view', 'update'])) {
        window.open(this.newTabRoute.href,'_blank');
      }
    },
    onRightClick(item, index, e){
      this.$refs.menu.open(e);
      this.newTabRoute=this.$router.resolve({ name: 'esc.entry.edit', params: { id: this.formattedESCs[index].id, } });
    },
    onContextMenu(event) {
      event.preventDefault();
    },
    orderList() {
      this.modelTableItem[0].table_fields = this.modelTableItem[0].table_fields.sort((one, two) => {
        return one.sort - two.sort;
      });
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.status) && !draggedElement.status
      );
    },
    openPopToSettingTable() {
      this.$modal.show('open_to_settings_table')
    },
    closeModal() {
      this.$modal.hide('open_to_settings_table');
    },
    onSubmitToSaveSettingTable() {
      let self = this;
      let json = {};
      for (let i = 0; i < this.fieldsToViewEdit.length; i++) {
        json[this.fieldsToViewEdit[i].key] = {
          name: this.fieldsToViewEdit[i].name,
          type: this.fieldsToViewEdit[i].type,
          status: this.fieldsToViewEdit[i].type_status,
          sort: i,
        }
      }
      let contents = {
        model_name: 'esc_table',
        table_fields: json,
      }
      let payload = {
        url: 'api/model_table/' + this.modelTableItem[0].id,
        id: this.id,
        contents: contents,
      }
      this.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then(result => {
        if (result.status) {
          this.$modal.hide('open_to_settings_table');
          self.sweetAlertSuccess(self.$t('general.successfully_created'));
          this.fieldsToViewEdit = [];
          this.getTableItem();

        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
      });

    },
    onRowClicked(item, index, event) {
      if (this.$route.query.redirect && this.$route.query.redirectName === 'order') {
        let self = this;
        this.sweetAlertConfirm(this.$t('order.are_you_sure_you_want_to_use_this_esc_for_order'), null, null, {
          'yes': this.$t('general.yes'),
          'no': this.$t('general.no'),
        }).then(result => {
          if (result) {
            self.$router.replace({
              name: 'order.create',
              params: self.$route.query,
              query: { ...this.$route.query, esc_id: this.formattedESCs[index].id, }
            })
          }
        })
      } else {
        if (this.isUserGranted('EscTable', ['create', 'update', 'view'])) {
          this.$router.push({ name: 'esc.entry.edit', params: { id: this.formattedESCs[index].id, } });
        }
        return false;
      }
    },
    filterResult() {
      localStorage.setItem(ESC_INDEX_START_DATE_STORAGE_NAME, moment(this.filters.start_date));
      localStorage.setItem(ESC_INDEX_END_DATE_STORAGE_NAME, moment(this.filters.end_date));
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onHitBottom();
    },
    onHitBottom($state = null) {
      if (this.loading) {
        return;
      }
      let self = this;
      if (self.isUserGranted('EscTable', ['viewAny'])) {
        let filters = _.cloneDeep(this.filters);
        this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
        this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
        this.$set(filters, 'order', this.order);
        this.$set(filters, 'sort', this.sort);

        if (this.filters.in_use === 0) {
          this.$set(filters, 'only_parents', null);
        } else {
          this.$set(filters, 'only_parents', 1);
        }

        self.$store.dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.escListUrl,
          filters: filters,
          '$state': $state,
        }).then(result => {
          this.infiniteId++;

          if (result.status) {
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        })
      }
    },
    resetFilters() {
      this.sort="desc",
      this.order= "id",
      this.setFilter({
        page: 1,
        per_page: 25,
        search: "",
        start_date: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
        user_id: null,
        selectedUsers: null
      })
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("esc.esc_table") }
    ]);

    if (!this.isUserGranted('EscTable', ['viewAny'])) {
      return false;
    }

    if (!this.filter) {
      this.resetFilters();
    }

    this.filters.end_date = localStorage.hasOwnProperty(ESC_INDEX_END_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(ESC_INDEX_END_DATE_STORAGE_NAME))
      : moment().add(1, 'd').format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(ESC_INDEX_START_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(ESC_INDEX_START_DATE_STORAGE_NAME))
      : moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT);
      
    this.filters.in_use=null;
    this.filters.in_use = this.$route.query.in_use ?? null

    this.$store.dispatch(REST_GET_ITEM, {
      url: 'api/users/customer representative',
      acceptPromise: true,
      filters: {}
    }).then((result) => {
      if (result.status) {
        self.userOptions = self.convertArrayToObjectByKey(result.data, 'id', 'name')
      }
    });
    this.getTableItem();
    setTimeout(this.onHitBottom, 500);
  },
  watch: {
    currentWaybillType(newValue) {
      this.onHitBottom();
    },
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
    currentStoreType(newValue) {
      this.onHitBottom()
    },
    sort() {
      this.filterResult();
    },
    order() {
      this.filterResult();
    },
  }
}
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
</style>
